import * as React from 'react';
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Alert,
    Button,
    CardHeader,
    Badge,
    FormGroup,
    Label,
    Input,
    FormText,
    CardFooter,
    Form
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

import { ApplicationState } from '../../store';
import * as ForgotPasswordModels from '../../models/signin/ForgotPassword';
import * as ForgotPasswordStore from '../../store/signin/ForgotPassword';
import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'

type Store =
    | (ForgotPasswordModels.GetForgotPasswordState & typeof ForgotPasswordStore.actionCreators)
    | (RedirectModels.RedirectState & typeof RedirectStore.actionCreators);

interface States {
    key: string;
    checkemail: boolean;
    email: string;
}

type OwnProps = {
    [key: string]: any;
}

type Props =
    & RouteComponentProps
    & OwnProps;

/*class ForgotPassword extends React.Component<any, { key: string, checkemail: boolean, email: string }, Store> {*/
class ForgotPassword extends React.Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            checkemail: true,
            email: ''
        }
    }

    componentDidMount() {
    }

    handleClickAccept = () => {
        if (this.state.email != "") {
            //console.log('handleClickAccept :' + this.state.key)
            var redirectPath = this.props.redirect
            var dataReq: any = {}
            dataReq['email'] = this.state.email
            //console.log(dataReq)
            this.props.requestForgotPassword(true, "GET", dataReq, redirectPath);
            this.setState({
                checkemail: false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter your email',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleChangeValue = (e: any) => {
        this.setState({
            email: e.target.value
        })
    }

    componentDidUpdate(nextProps, nextState) {
        const { isLoadGetForgotPassword, responseGetForgotPassword, statusGetForgotPassword, messageGetForgotPassword } = this.props
        if (isLoadGetForgotPassword && statusGetForgotPassword == 200 && !this.state.checkemail) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Success!',
                text: 'A request has been recived to your email , Pls check email',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestForgotPassword(true, "CLEAR", this.state.key);
                    this.props.history.push('/auth/signin');
                }
            })
        } else if (isLoadGetForgotPassword && statusGetForgotPassword == 400 && !this.state.checkemail) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            //var msg = JSON.parse(messageGetForgotPassword)
            Swal.fire({
                title: 'Error!',
                text: messageGetForgotPassword,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestForgotPassword(true, "CLEAR", this.state.key);
                }
            })
        }
    }

    render() {
        return (
            <Container >
                <Row className="mt-5">
                    <Col lg={2} />
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-center">
                                        <h3>Forgot Password</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>E-mail :</Label>
                                            <Input type="text" name="email" id="email" onChange={this.handleChangeValue} required />
                                            <FormText>Please enter your e-mail to reset your password.</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <Button
                                            type="submit"
                                            className="mt-3 btn btn-info btn-block"
                                            style={{
                                                backgroundColor:
                                                    colors.btnSSO_Orange,
                                                borderColor:
                                                    colors.btnSSO_Orange
                                            }}
                                            onClick={this.handleClickAccept}
                                        >
                                            Send Reset Password Link
                                        </Button>
                                    </Col>
                                    <Col>
                                        {/*<Link to="/auth/signin" className="text-light">*/}
                                        {/*    <Button type="submit" className="mt-3 btn btn-primary btn-block" style={{ backgroundColor: colors.btnSSO_Blue, borderColor: colors.btnSSO_Blue }}>Back</Button>*/}
                                        {/*</Link>*/}
                                        <button
                                            className="mt-3 btn btn-primary btn-block"
                                            type="button"
                                            onClick={() => this.props.history.goBack()}
                                            style={{
                                                backgroundColor:
                                                    colors.btnSSO_Blue,
                                                borderColor:
                                                    colors.btnSSO_Blue
                                            }}
                                        >
                                            Back
                                        </button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg={2} />
                </Row>
            </Container>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.ForgotPassword, ...state.Redirect }),
    ({ ...ForgotPasswordStore.actionCreators, ...RedirectStore.actionCreators })
)(ForgotPassword);
/*export default RegisterComponent*/