import * as React from 'react';
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label,
    FormText,
    Card,
    CardBody,
    Container
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';
import queryString from "query-string"

import { ApplicationState } from '../../store';
import * as Models from '../../models/register';
import * as Stores from '../../store/Register';
import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';
import Swal from 'sweetalert2';
import colors from '../../default/Colors';
import { loadavg } from 'os';

import PDPAInfoModal from './PDPAInfoModal';

type Store =
    | (Models.RegisterState & typeof Stores.actionCreators)
    | (RedirectModels.RedirectState & typeof RedirectStore.actionCreators);

interface States {
    queryEmail: string;
    modelRegister: Models.Register;
    brith_date_bind: string;
    redirect: string;
    checkDisablebtn: boolean;
    passValidate: any;
    passTooltips: boolean;
    isCorrectPattern: { email: boolean; firstName: boolean; lastName: boolean };
    requireFieldIsEmpty: boolean;
    isOpenPDPAInfoModal: boolean;
    isAcceptPDPA: boolean;
    loading: boolean;
}

type OwnProps = {
    [key: string]: any;
}

type Props =
    & RouteComponentProps
    & OwnProps;

class RegisterComponent extends React.Component<Props, States> {
    constructor(props: any) {
        super(props);

        this.state = {
            queryEmail: '',
            modelRegister: {
                activation_channel: '',
                username: '',
                password: '',
                retype_password: '',
                national_id: '',
                first_name: '',
                last_name: '',
                first_name_en: '',
                last_name_en: '',
                nickname: '',
                gender: '',
                birth_date: '',
                mobile_phone_number: '',
                email: '',
                facebook_id: '',
                line_id: '',
                google_id: '',
                azure_id: ''
            },
            brith_date_bind: '',
            redirect: '',
            checkDisablebtn: true,
            passValidate: {
                error: '',
                length: false,
                numLower: false,
                numNums: false,
                numSpecials: false,
                numUpper: false,
                result: false
            },
            passTooltips: false,
            isCorrectPattern: {
                email: false,
                firstName: false,
                lastName: false
            },
            requireFieldIsEmpty: true,
            isOpenPDPAInfoModal: false,
            isAcceptPDPA: false,
            loading: true
        };

        this.save = this.save.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleTogglePDPAInfoModal = this.handleTogglePDPAInfoModal.bind(this);
        this.handleToggleAcceptPDPA = this.handleToggleAcceptPDPA.bind(this);
    }

    componentDidMount() {
        const { email, redirect } = queryString.parse(this.props.location?.search);
        this.props.history.replace({
            pathname: '/citizen/register',
            search: ''
        });

        this.setState({
            queryEmail: email as string,
            isCorrectPattern: {
                email: email ? true : false,
                firstName: false,
                lastName: false
            },
            redirect: redirect as string,
            modelRegister: {
                activation_channel: '',
                username: email as string,
                password: '',
                retype_password: '',
                national_id: '',
                first_name: '',
                last_name: '',
                first_name_en: '',
                last_name_en: '',
                nickname: '',
                gender: '',
                birth_date: '',
                mobile_phone_number: '',
                email: email as string,
                facebook_id: '',
                line_id: '',
                google_id: '',
                azure_id: ''
            }
        });

        if (email && redirect) {
            fetch("/v1/citizen/" + email)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        var redirectPath = this.props.redirect;
                        if (!redirectPath) {
                            redirectPath = redirect
                        }
                        const timeout = setTimeout(() => {
                            // 👇️ redirects to an external URL
                            window.location.replace(redirectPath);
                        }, 500);

                        //document.getElementsByClassName(
                        //    'Loader__background'
                        //)[0].className = 'Loader__background hide';

                        this.setState({
                            loading: false
                        });
                    },
                    (error) => {
                        //document.getElementsByClassName(
                        //    'Loader__background'
                        //)[0].className = 'Loader__background hide';

                        //console.log(error);

                        this.setState({
                            loading: false
                        });
                    }
                )
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate() {
        if (
            !this.state.checkDisablebtn &&
            this.state.modelRegister.email &&
            this.state.modelRegister.first_name &&
            this.state.modelRegister.last_name &&
            this.state.requireFieldIsEmpty &&
            this.state.isAcceptPDPA
        ) {
            this.setState({ requireFieldIsEmpty: false });
        } else if (
            this.state.checkDisablebtn &&
            !this.state.requireFieldIsEmpty
        ) {
            this.setState({ requireFieldIsEmpty: true });
        }
    }

    private handleTogglePDPAInfoModal() {
        this.setState(prevState => ({
            ...prevState,
            isOpenPDPAInfoModal: !prevState.isOpenPDPAInfoModal
        }));
    }

    private save() {
        if (!this.state.isAcceptPDPA) {
            Swal.fire({
                title: 'Warning!',
                text: 'กรุณาเลือกยินยอมในการเก็บรวบรวม ใช้ หรือ เปิดเผยข้อมูลส่วนบุคคล',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            });

            return;
        }

        if (this.validateEmail(this.state.modelRegister.email)) {
            if (this.state.modelRegister.username != '') {
                var redirectPath = this.props.redirect;
                if (!redirectPath) {
                    redirectPath = this.state.redirect
                }
                this.props.requestFunction(
                    this.state.modelRegister,
                    'POST',
                    redirectPath
                );
                document.getElementsByClassName(
                    'Loader__background'
                )[0].className = 'Loader__background show';
            } else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please enter your username',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                    }
                });
            }
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter your E-mail',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                }
            });
        }
    }

    validateEmail(email: string) {
        //const re1 = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@scg.com$/;
        //const re2 = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@qmix.com$/;

        //if (!re1.test(email) && !re2.test(email)) {
        //    return false;
        //} else {
        //    return true;
        //}

        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
    }

    validatePattern(id: string, payload: string) {
        // const re = new RegExp(/^\s+/, 'g');
        // console.log('[Debug] re ->', re.test(payload));

        switch (id) {
            case 'email': {
                this.setState(prevState => ({
                    isCorrectPattern: {
                        email: payload !== '',
                        firstName: prevState.isCorrectPattern.firstName,
                        lastName: prevState.isCorrectPattern.lastName
                    }
                }));

                break;
            }
            case 'first_name': {
                this.setState(prevState => ({
                    isCorrectPattern: {
                        email: prevState.isCorrectPattern.email,
                        firstName: payload.trim() !== '',
                        lastName: prevState.isCorrectPattern.lastName
                    }
                }));

                break;
            }
            case 'last_name': {
                this.setState(prevState => ({
                    isCorrectPattern: {
                        email: prevState.isCorrectPattern.email,
                        firstName: prevState.isCorrectPattern.firstName,
                        lastName: payload.trim() !== ''
                    }
                }));

                break;
            }
            default:
                break;
        }

        return payload.trim();
    }

    private handleChangeValue(e: any) {
        var data = this.state.modelRegister;
        /*data[e.target.id] = e.target.value;*/
        //if (e.target.id == "username") {
        if (e.target.id == 'email') {
            const emailTrimeWhitespace = this.validatePattern(
                e.target.id,
                e.target.value
            );
            data.username = emailTrimeWhitespace;
        }
        if (e.target.id == 'password') {
            data.password = e.target.value;
            var ans = this.isOkPass(e.target.value);
            console.log(ans);
            this.setState({
                passValidate: ans
            });
        }
        if (e.target.id == 'retype_password') {
            data.retype_password = e.target.value;
        }
        if (e.target.id == 'national_id') {
            data.national_id = e.target.value;
        }
        if (e.target.id == 'first_name') {
            const firstNameTrimeWhitespace = this.validatePattern(
                e.target.id,
                e.target.value
            );
            data.first_name = firstNameTrimeWhitespace;
        }
        if (e.target.id == 'last_name') {
            const lastNameTrimeWhitespace = this.validatePattern(
                e.target.id,
                e.target.value
            );
            data.last_name = lastNameTrimeWhitespace;
        }
        if (e.target.id == 'first_name_en') {
            data.first_name_en = e.target.value;
        }
        if (e.target.id == 'last_name_en') {
            data.last_name_en = e.target.value;
        }
        if (e.target.id == 'nickname') {
            data.nickname = e.target.value;
        }
        if (e.target.id == 'gender') {
            data.gender = e.target.value;
        }
        //if (e.target.id == "birth_date") {
        //    data.birth_date = e.target.value;
        //}
        if (e.target.id == 'mobile_phone_number') {
            data.mobile_phone_number = e.target.value;
        }
        if (e.target.id == 'email') {
            const emailTrimeWhitespace = this.validatePattern(
                e.target.id,
                e.target.value
            );
            data.email = emailTrimeWhitespace;
            console.log('[Debug] email', '^' + data.email + '$');
        }
        if (e.target.id == 'facebook_id') {
            data.facebook_id = e.target.value;
        }
        if (e.target.id == 'line_id') {
            data.line_id = e.target.value;
        }
        if (e.target.id == 'google_id') {
            data.google_id = e.target.value;
        }
        if (e.target.id == 'azure_id') {
            data.azure_id = e.target.value;
        }

        this.setState({
            modelRegister: data
        });

        // if (
        //     this.state.modelRegister.password != '' ||
        //     this.state.modelRegister.retype_password != ''
        // ) {
        //     if (
        //         this.state.modelRegister.password !=
        //         this.state.modelRegister.retype_password
        //     ) {
        //         this.setState({
        //             checkDisablebtn: true
        //         });
        //     } else {
        //         this.setState({
        //             checkDisablebtn: false
        //         });
        //     }
        // } else {
        //     this.setState({
        //         checkDisablebtn: true
        //     });
        // }

        if (
            this.state.modelRegister.password ||
            this.state.modelRegister.retype_password
        ) {
            if (
                this.state.modelRegister.password !==
                this.state.modelRegister.retype_password
            ) {
                this.setState({ checkDisablebtn: true });
            } else {
                this.setState({ checkDisablebtn: false });
            }
        }

        // console.log('[Debug] checkDisablebtn', this.state.checkDisablebtn);
        // console.log('[Debug] enable btn register', this.state.modelRegister);

        // if (
        //     !this.state.checkDisablebtn &&
        //     this.state.modelRegister.email &&
        //     this.state.modelRegister.first_name &&
        //     this.state.modelRegister.last_name
        // ) {
        //     this.setState({ requireFieldIsEmpty: false });
        // } else {
        //     this.setState({ requireFieldIsEmpty: true });
        // }
    }

    handleChangeDate = (date, event) => {
        console.log(date);
        var dates = new Date(date),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day;
        console.log(dataRs);
        var data = this.state.modelRegister;
        data.birth_date = dataRs;
        this.setState({
            brith_date_bind: date,
            modelRegister: data
        });
    };

    isOkPass(p) {
        console.log('-----------');
        console.log(p);
        var anUpperCase = /[A-Z]/;
        var aLowerCase = /[a-z]/;
        var aNumber = /[0-9]/;
        var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
        var obj: any = {};
        obj.result = false;

        if (p.length < 8) {
            obj.length = false;
            obj.error = 'Not long enough!';
        } else {
            obj.length = true;
        }

        var numUpper = 0;
        var numLower = 0;
        var numNums = 0;
        var numSpecials = 0;
        for (var i = 0; i < p.length; i++) {
            if (anUpperCase.test(p[i])) numUpper++;
            else if (aLowerCase.test(p[i])) numLower++;
            else if (aNumber.test(p[i])) numNums++;
            else if (aSpecial.test(p[i])) numSpecials++;
        }

        if (numUpper < 1) {
            obj.result = false;
            obj.error = 'Wrong Format!';
            obj.numUpper = false;
        } else {
            obj.numUpper = true;
        }

        if (numLower < 1) {
            obj.result = false;
            obj.error = 'Wrong Format!';
            obj.numLower = false;
        } else {
            obj.numLower = true;
        }

        if (numNums < 1) {
            obj.result = false;
            obj.error = 'Wrong Format!';
            obj.numNums = false;
        } else {
            obj.numNums = true;
        }

        if (numSpecials < 1) {
            obj.result = false;
            obj.error = 'Wrong Format!';
            obj.numSpecials = false;
        } else {
            obj.numSpecials = true;
        }

        if (
            obj.numUpper &&
            obj.numLower &&
            obj.numNums &&
            obj.numSpecials &&
            obj.length
        ) {
            obj.result = true;
        }
        return obj;
    }

    private handleToggleAcceptPDPA(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ isAcceptPDPA: event.target.checked });
    }

    toggleTooltips = () => {
        if (this.state.passTooltips) {
            this.setState({
                passTooltips: false
            });
        } else {
            this.setState({
                passTooltips: true
            });
        }
    };

    render() {
        const { isLoadingRegister, statusRegister, messageRegister } =
            this.props;

        if (this.state.loading) {
            var loader = document.getElementsByClassName('Loader__background')[0]
            if (loader) {
                loader.className = 'Loader__background show';
            }
        }
        else {
            var loader = document.getElementsByClassName('Loader__background')[0]
            if (loader) {
                loader.className = 'Loader__background hide';
            }
        }

        if (isLoadingRegister && statusRegister == 200) {
            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background hide';
            Swal.fire({
                title: 'Success!',
                text: 'Register success , Please authentication e-mail',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.props.requestFunction('', 'CLEAR');
                    this.props.history.push('/auth/signin');
                    //return <Redirect to='/auth/signin' />
                }
            });
        } else if (isLoadingRegister && statusRegister != 200) {
            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background hide';
            this.props.requestFunction('', 'CLEAR');
            Swal.fire({
                title: 'Error!',
                text: messageRegister,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then(result => {
                if (result.value) {
                }
            });
        }

        const { modelRegister, queryEmail } = this.state;
        const email = modelRegister.email;

        /*console.log("response");
        console.log(response);
        console.log("---");*/

        return (
            <Container>
                <Row style={{ paddingTop: '20px' }}>
                    <Col lg={1} />
                    <Col lg={10}>
                        <Card>
                            <CardBody>
                                <div>
                                    <h1>Register Citizen</h1>
                                </div>
                                <hr />
                                <div style={{ paddingTop: '30px' }}>
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label>E-mail :</Label>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                    valid={
                                                        this.state
                                                            .isCorrectPattern
                                                            .email
                                                    }
                                                    invalid={
                                                        !this.state
                                                            .isCorrectPattern
                                                            .email
                                                    }
                                                    value={email}
                                                    disabled={queryEmail ? true : false}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Username :</Label>*/}
                                    {/*            <Input type="text" name="username" id="username" onChange={this.handleChangeValue} autoComplete="new-password" />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label>Password :</Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                    autoComplete="new-password"
                                                    data-tip
                                                    data-for="global"
                                                    valid={
                                                        this.state.passValidate[
                                                        'result'
                                                        ]
                                                    }
                                                    invalid={
                                                        !this.state
                                                            .passValidate[
                                                            'result'
                                                        ]
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <ReactTooltip
                                                    id="global"
                                                    place="left"
                                                    type="dark"
                                                    effect="solid"
                                                >
                                                    <ul>
                                                        <li
                                                            style={{
                                                                color: this
                                                                    .state
                                                                    .passValidate[
                                                                    'length'
                                                                ]
                                                                    ? 'green'
                                                                    : 'white'
                                                            }}
                                                        >
                                                            {' '}
                                                            Minimum Password 8
                                                            character
                                                        </li>
                                                        <li
                                                            style={{
                                                                color: this
                                                                    .state
                                                                    .passValidate[
                                                                    'numUpper'
                                                                ]
                                                                    ? 'green'
                                                                    : 'white'
                                                            }}
                                                        >
                                                            At least 1 UPPER
                                                            CASE
                                                        </li>
                                                        <li
                                                            style={{
                                                                color: this
                                                                    .state
                                                                    .passValidate[
                                                                    'numLower'
                                                                ]
                                                                    ? 'green'
                                                                    : 'white'
                                                            }}
                                                        >
                                                            At least 1 LOWWER
                                                            CASE
                                                        </li>
                                                        <li
                                                            style={{
                                                                color: this
                                                                    .state
                                                                    .passValidate[
                                                                    'numSpecials'
                                                                ]
                                                                    ? 'green'
                                                                    : 'white'
                                                            }}
                                                        >
                                                            At least 1 Special
                                                            CASE
                                                        </li>
                                                    </ul>
                                                </ReactTooltip>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="position-relative">
                                                <Label>Re-Password :</Label>
                                                <Input
                                                    type="password"
                                                    name="retype_password"
                                                    id="retype_password"
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                    autoComplete="new-password"
                                                    invalid={
                                                        this.state
                                                            .checkDisablebtn
                                                    }
                                                    valid={
                                                        !this.state
                                                            .checkDisablebtn
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <FormText>
                                                    Please enter match your
                                                    password.
                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>E-mail :</Label>*/}
                                    {/*            <Input type="email" name="email" id="email" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label>First Name :</Label>
                                                <Input
                                                    type="text"
                                                    name="first_name"
                                                    id="first_name"
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                    required
                                                    valid={
                                                        this.state
                                                            .isCorrectPattern
                                                            .firstName
                                                    }
                                                    invalid={
                                                        !this.state
                                                            .isCorrectPattern
                                                            .firstName
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Last Name :</Label>
                                                <Input
                                                    type="text"
                                                    name="last_name"
                                                    id="last_name"
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                    required
                                                    valid={
                                                        this.state
                                                            .isCorrectPattern
                                                            .lastName
                                                    }
                                                    invalid={
                                                        !this.state
                                                            .isCorrectPattern
                                                            .lastName
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Frist Name (ENG):</Label>*/}
                                    {/*            <Input type="text" name="first_name_en" id="first_name_en" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Last Name (ENG):</Label>*/}
                                    {/*            <Input type="text" name="last_name_en" id="last_name_en" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>National ID :</Label>*/}
                                    {/*            <Input type="text" name="national_id" id="national_id" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col lg={3}>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Gender :</Label>*/}
                                    {/*            <Input type="select" name="gender" id="gender" onChange={this.handleChangeValue}>*/}
                                    {/*                <option value="" hidden selected>Select Gender...</option>*/}
                                    {/*                <option value="Male">Male</option>*/}
                                    {/*                <option value="Female">Female</option>*/}
                                    {/*            </Input>*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col lg={3}>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Birth Date :</Label>*/}
                                    {/*            <br />*/}
                                    {/*            */}
                                    {/*<Input type="text" name="birth_date" id="birth_date" onChange={this.handleChangeValue} />*/}
                                    {/*            <DatePicker*/}
                                    {/*                selected={this.state.brith_date_bind}*/}
                                    {/*                onChange={this.handleChangeDate}*/}
                                    {/*                customInput={<Input type="text" name="birth_date" id="birth_date" className="form-control" style={{ width: '290px' }} />}*/}
                                    {/*                dateFormat="dd/MM/yyyy"*/}
                                    {/*                placeholderText="dd/mm/yyyy"*/}
                                    {/*                peekNextMonth*/}
                                    {/*                showMonthDropdown*/}
                                    {/*                showYearDropdown*/}
                                    {/*                dropdownMode="select"*/}
                                    {/*            />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col lg={6}>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Nickname :</Label>*/}
                                    {/*            <Input type="text" name="nickname" id="nickname" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Facebook ID</Label>*/}
                                    {/*            <Input type="text" name="facebook_id" id="facebook_id" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Line ID :</Label>*/}
                                    {/*            <Input type="text" name="line_id" id="line_id" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Google ID</Label>*/}
                                    {/*            <Input type="text" name="google_id" id="google_id" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label>Azure ID :</Label>*/}
                                    {/*            <Input type="text" name="azure_id" id="azure_id" onChange={this.handleChangeValue} />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label for="exampleFile">Avatar:</Label>*/}
                                    {/*            <Input type="file" name="file" id="exampleFile" />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <Col
                                            className="d-flex align-items-center"
                                            style={{ gap: '8px' }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={this.state.isAcceptPDPA}
                                                onChange={this.handleToggleAcceptPDPA}
                                                style={{ width: '16px', height: '16px' }}
                                            />
                                            <span>
                                                ยินยอมในการเก็บรวบรวม ใช้ หรือ
                                                {' '}
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    className="text-primary user"
                                                    onClick={this.handleTogglePDPAInfoModal}
                                                >
                                                    เปิดเผยข้อมูลส่วนบุคคล
                                                </span>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <button
                                                type="submit"
                                                onClick={this.save}
                                                className="mt-3 btn btn-info btn-block"
                                                style={{
                                                    backgroundColor:
                                                        colors.btnSSO_Orange,
                                                    borderColor:
                                                        colors.btnSSO_Orange
                                                }}
                                                disabled={
                                                    this.state
                                                        .requireFieldIsEmpty
                                                }
                                            >
                                                <b>Register</b>
                                            </button>
                                        </Col>
                                        {!email &&
                                            <Col>
                                                <button
                                                    className="mt-3 btn btn-primary btn-block"
                                                    type="button"
                                                    onClick={() => this.props.history.goBack()}
                                                    style={{
                                                        backgroundColor:
                                                            colors.btnSSO_Blue,
                                                        borderColor:
                                                            colors.btnSSO_Blue
                                                    }}
                                                >
                                                    Back
                                                </button>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={1} />
                </Row>

                <PDPAInfoModal
                    isOpen={this.state.isOpenPDPAInfoModal}
                    onToggle={this.handleTogglePDPAInfoModal}
                />
            </Container>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.register, ...state.Redirect }),
    { ...Stores.actionCreators, ...RedirectStore.actionCreators }
)(RegisterComponent);
/*export default RegisterComponent*/
