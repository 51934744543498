import * as React from 'react';
import { connect } from 'react-redux';
import FacebookButtonComponent from './FacebookButtonComponent';
import GoogleButtonComponent from './GoogleButtonComponent';
import AzureButtonComponent from './AzureButtonComponent';
import queryString from "query-string"
import './style/Signin.css';

import * as AppStore from '../../store/AppState'
import * as SocialModel from '../../models/AppState'
import { ApplicationState } from "../../store";
import LoginButtonComponent from './LoginButtonComponent';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';
import Swal from 'sweetalert2';
import colors from '../../default/Colors'
import LoginButtonPoolComponent from './LoginButtonPoolComponent';
var imgFile = require('../../images/F1 ERP_Logo.png');

type State = {
    version: string,
    username: string,
    password: string,
    currentUser: any,
    message: string,
    isLoginSuccess: boolean,
    token: string,
    endpoint: string,
    mode: string,
    socialAuth?: SocialModel.SocialAuth
};

type OwnProps = {
    [key: string]: any;
};

type Props =
    & RouteComponentProps
    & OwnProps;

class SignInComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            username: '',
            password: '',
            currentUser: null,
            message: '',
            isLoginSuccess: false,
            token: '',
            endpoint: '',
            mode: '',
            version: '',
            socialAuth: undefined
        }

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    componentDidMount() {
        const { token, mode, redirect } = queryString.parse(this.props.location?.search);
        console.log(redirect)
        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                }
            )

        this.props.requestAppInit();

        if (token != null) {
            var jwt = this.parseJwt(token)
            if (jwt.extra != undefined) {
                var extra = JSON.parse(jwt.extra)
                var callbackId = extra.callback_id;
                if (callbackId != undefined) {
                    console.log(callbackId);
                    fetch('/v1/citizen/auth/callback?id=' + callbackId, {
                        method: 'GET',
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    token: token as string,
                                    endpoint: result.endpoint,
                                    mode: mode as string
                                })
                            },
                            (error) => {
                                console.log(error)
                                Swal.fire({
                                    title: 'ติดต่อผู้ดูแลระบบ!',
                                    text: 'ไม่มีการกำหนด CallBack ของระบบ กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ',
                                    icon: 'warning',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ตกลง',
                                    allowOutsideClick: false,
                                }).then((result) => {

                                })
                            }
                        )
                }
            }
        } else {
            this.setState({
                mode: mode as string
            })
        }
        if (redirect != undefined && redirect != "") {
            this.props.requestFunctionRedirect(redirect, "SET");
        }
    }

    componentDidUpdate() {
        var { isLoaded, socialAuth } = this.props;
        if (isLoaded && this.state.socialAuth === undefined) {
            this.setState({
                socialAuth: socialAuth
            })
        }
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    private onChangeUserName(e: any) {
        const { name, value } = e.target
        this.setState({
            username: value
        })
    }

    private onChangePassword(e: any) {
        const { name, value } = e.target
        this.setState({
            password: value
        })
    }

    rememberMe = (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            localStorage.setItem('SSO-WEB_REMEMBER', 'true')
        } else {
            localStorage.removeItem('SSO-WEB_REMEMBER')
        }
    }

    //private testCallback() {
    //    var aa = {
    //        message: 'window.postMessage testCallback'
    //    };

    //    (window as any).chrome.webview.postMessage('testCallback');
    //    //window.postMessage(aa, '*');

    //    console.log(aa);
    //}

    render() {
        let loginObject = this.state;
        var { isLoaded, socialAuth } = this.props;

        if (!isLoaded) {
            return <div></div>
        }

        return (
            <form >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className="col-lg-4 col-md-6 col-sm-12 signin-font">
                        <div className="div-logo">
                            < img src={imgFile} className="logo-login" alt="file" />
                        </div>
                        <h3 >Sign In</h3>

                        <div className="form-group">
                            <label style={{ fontFamily: "sans-serif" }}>Username</label>
                            <input type="Text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
                        </div>
                        <div className="row">
                            {/*<div className="col-lg-6">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <div className="custom-control custom-checkbox">*/}
                            {/*            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={(e) => this.rememberMe(e)} />*/}
                            {/*            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-lg-6" style={{ maxWidth: '100%', WebkitFlex: '0 0 100%' }}>
                                <div className="d-flex justify-content-end">
                                    <Link to="/forgotpassword">
                                        <a>Forgot Password</a>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <LoginButtonComponent loginObject={loginObject} history={this.props.history} />
                        {/* <LoginButtonF1Component loginObject={loginObject} /> */}
                        {/*<LoginButtonPoolComponent loginObject={loginObject} />*/}

                        {/*<p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p>*/}
                        {/* <FacebookButtonComponent loginObject={loginObject} />

                        <GoogleButtonComponent loginObject={loginObject} /> 

                        <AzureButtonComponent loginObject={loginObject} /> */}

                        {/*<Link to="/citizen/register">*/}
                        {/*<button className="mt-3 btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }}><b>Register</b></button>*/}
                        {/*</Link>*/}
                        <div>
                            <div className="d-flex justify-content-center" style={{ marginTop: 30, marginBottom: 5 }}>
                                Don't have an account yet? &nbsp;
                                <Link
                                    to={{
                                        pathname: '/citizen/register',
                                        state: {
                                            previousSearch: this.props.location?.search
                                        }
                                    }}
                                >
                                    <a>Register</a>
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center" >
                            <div style={{ borderBottom: 1, borderBottomStyle: 'solid', borderBottomColor: 'grey', width: '75%' }}></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center" style={{ marginTop: 5 }}>
                                <p>How to Register &nbsp;</p>
                                <a target="_blank" href="https://f1-help.thailanderp.com/Help/HelpSubmodule.aspx?cid=00044&hcode=00501&hid=41fc28c4-e60c-4b09-9094-cee3e31e9a72&xlevel=0">Here</a>
                            </div>
                        </div>

                        {socialAuth &&
                            <div className="signin-font">
                                {/*<div className="d-flex justify-content-center">*/}
                                {/*    Sign in with*/}
                                {/*</div>*/}
                                {socialAuth.facebook_app_id &&
                                    <div>
                                        <FacebookButtonComponent loginObject={loginObject} />
                                    </div>
                                }
                                {socialAuth.google_client_id &&
                                    <div>
                                        <GoogleButtonComponent loginObject={loginObject} />
                                    </div>
                                }
                                {socialAuth.azure_client_id &&
                                    {/*<div>
                                        <AzureButtonComponent loginObject={loginObject} />
                                    </div>*/}
                                }
                            </div>
                        }

                        <span style={{ fontSize: '8pt', paddingLeft: '5px', float: 'right', paddingTop: '20px' }}>Version {this.state.version}</span>

                        {/* <button type="button" className="mt-3 btn btn-info btn-block" onClick={this.testCallback}><b>Test Callback</b></button> */}

                    </div>
                </div>
            </form>

        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.appState, ...state.Redirect }),
    ({ ...AppStore.actionCreators, ...RedirectStore.actionCreators })
)(SignInComponent);

//export default SignInComponent